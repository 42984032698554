import React from 'react'
import styled from 'styled-components'
import siteConfig from '../../data/siteConfig'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { withPrefix } from "gatsby"
import loadable from '@loadable/component'
import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'
import { aboutMe } from '../../data/myAbout'

const Layout = loadable(() => import('../components/layout'))

const About = ({className, location }) => {
	const title = "About"
	const { keywords, about } = siteConfig
	return (
    <Layout location={location}>
      <SEO
        title={title}
        keywords={keywords}
      />

      <Hero
        heroImg={withPrefix('/images/mountains2.jpg')}
        title={title}
      />

      <Wrapper className={className}>
        <Container className="page-content" fluid>
					<Row>
						<Col xs={4} className='about-me'>
							<img
								className='about__image'
								src={withPrefix(about.image)}
								alt="corey's family"
							/>
							<div>
								<h1 className='about__text'>About Me</h1>
								<h3 className='about__text'>{about.summary}</h3>
								<p dangerouslySetInnerHTML={{__html: aboutMe }}></p>
							</div>
						</Col>
					</Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default styled(About)`
	.page-content {
		max-width: 100%;
		margin-bottom: 40px;
	}
  .about-me {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
		text-align: center;
  }
  .about__image {
    box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.75);
    max-width: 600px;
    margin: 0 auto 64px;
		width: 100%;
		height: 100%;
  }
	.about__text {
		text-transform: uppercase;
	}
`
