module.exports = {
  aboutMe: `<p>Hi, my name is Corey. I am an American software engineer from Virginia, but have spent the last 8 years as a web developer working both remotely and for companies. First of all, thank you for reading my cv/blog. It's such a pleasure to have you here! Just to make sure: I am the guy standing behind the baby and the beautiful woman in the picture :)</p> 

<p>I graduated with a Bachelor of Arts in Music Performance in 2008. Following my studies, I enlisted in the Navy as a Sonar Technician, where I uncovered a passion for programming during my training at the Navy Advanced Technical Training School. There, I honed my skills in C programming, focusing on micro-controllers and embedded systems. My curiosity quickly expanded to include open-source platforms and web development.</p>

<p>After completing my service in 2012, I transitioned into the civilian sector, securing my first full-time role as a software engineer at a web development firm in San Diego. This position allowed me to broaden my technical expertise, mastering languages and frameworks such as PHP, Python, and JavaScript. Alongside my work, I pursued computer science courses to deepen my understanding of software engineering principles. These formative years not only enhanced my technical skill set but also ignited a passion for the evolving landscape of web-based technologies.</p

<p>During this period, I also ventured into entrepreneurship, launching two small businesses, which I continue to manage successfully. The entrepreneurial experience has been invaluable, enabling me to provide consulting services for the launch of several other ventures. Over time, I established myself as a web developer specializing in Restful APIs and front-end development with React and Angular.</p

<p>While I’ve enjoyed the flexibility of freelance work, particularly as I manage my businesses, I am now seeking a full-time opportunity with a dynamic company that shares my passion for innovation. With my growing family—my wife and I are expecting a baby—the stability of a full-time role has become increasingly appealing. I am eager to contribute my expertise to a forward-thinking organization and am open to opportunities in web and software development. Please feel free to reach out if you would like to connect.</p>

<h4>Values and Mission</h4>

<p>I try my best to have a positive impact on the world. 80% of my code that is not being written for a customer is open source for everyone on GitHub. Every time I build a product/service for myself, I immediately think about how to open source it for others. I also believe that most educational content is too expensive. Whereas a programming course for $99 might be affordable in the US, it's out of reach for any eager programmer in South East Asia. So I have recently begun a blog on this very website to teach the world what I have learned. My favorite aspect about the internet is the free and open sharing of information. So I strive to use it for just that. I have lived a fortunate life and I consider myself a lucky man with a wonderful family, so I hope that I can give something back.</p>

<h4>Passions beyond Software Development</h4>

<p>Even though I do a lot of programming in my professional and spare time, constantly trying to keep my programming chops up to date and compatible in these fast paced times we live in, there are other things I treasure too. I am an avid reader, but unlike the average lover of novels, I am a science and textbook freak. The joy I get from reading a book on organic chemistry is probably something akin to what my wife gets out of reading her transcendental fiction books.</p>

<p> I am also an avid sailor with a a dream for my wife and I to spend our retirement sailing the world in our own little wind and sun powered home away from home. But that is a dream that is 15+ years in the making. Not an immediate past time other than the time we can spend on the precious time we can get to spend on the Chesapeake bay at my parents place on a mix between their 'nice' fishing/rec boat and my years in the making fix up job of a sailboat we can hope to use to experience our great adventures in. And there will be enough room to bring our son (or future babies/blessings) along with us lol.</p>

<p>In my earlier life, I wanted to do music. I even went to a specialty center high school for the performing arts and college for music performance on scholarship. But after many years of performing, I came to the realization that I didn't want to make a living playing music. I also loss interest in the actual act of performing for other people. I get much more enjoyment out of writing and playing for myself. And since becoming a father, I have found that I don't even have time to do that any more. Nowadays I just enjoy listening to my snobby collection of Radiohead, classical and jazz lol.</p>

<p>Without a doubt the most important part of my life is my family. Without them I wouldn't be able to sustain my every day doings. My life would be too chaotic. I learned a long time ago the difficulties I can face as a single man. I was just fortunate enough to find the woman of my dreams and the family she has both given me and takes care of. She keeps our life in order, clean and fed. She also changes the dirty diapers a lot more than I do :). And we have a highly autistic 3 year old at the time of this writing so when it comes to THAT part of the parenting experience, I can guarantee to any other fellow parents out there whose children aren't struggling with this developmental issue, you have it easy lol. But with all that being said, I can say with utmost certainty that everything I do, every decision I make and every job I take gets done with them in mind first and foremost.</p>

<p>As of 2020, this website and blog is fairly new. But I intend on making it an important part of my free time. I not only want to use it as a personal marketing platform, but for a way to educate and promote my open source projects. Though I have been working with open source software for many years, I have only recently been making it one of my main focuses. Considering the way the web development field of software has been moving with the new separation of concerns and the end of the native software era, everyone should be paying attention to it...in my opinion that is. I intend on regularly using my github account to publicly post boilerplate code and connect those repositories to 'how to' blog posts in relation. I hope that it will be of use to people.</p>

<p>If you like my posts for any reason other than having an interest in being my future employer, or hiring my temporary services, I will be posting a patreon link once the blog begins to gain enough traction. In the meantime, thank you for being one of the few people who have happened upon this little corner of the internet about my life!</p>

<h4>The Tools I Use:</h4>

<strong>AWS</strong><br />

<p>Though not always my first choice when it comes to deploying web based material, Amazon is without a doubt a leader in devops tech and making the world of deploying, managing and financing large enterprise level apps much easier. Their separation of concerns into an easy to use GUI based infrastructure has always been an impressive move in my opinion (that's not to say that I have been impressed with just about everything Amazon has done for the tech community). I am personally an old fashioned Linux user who has a strong enough background in Linux sys/server administration that I can and usually do prefer to do all of the work on my own. But the recent companies I have worked for have sworn by it and would prefer to use tools like lambda, fargate, lightsale and Route 53 to make their server work easier, I have always been an advocate to handling all of that myself, free of cost and the way it is really done behind the scenes anyway lol. But knowing that this is the direction that the web infrastructure world is beginning to work, I have had no problem with spending the last 2 years getting very proficient with AWS. If a client or CEO wants me to spend little time on the infrastructure and more on the code-base than who am I to argue? I am just an avid Linux command line operator who can solve almost any digital problem with the right open source tools without ever having to deal with the ever changing customer support of AZURE, AWS or Apple Store.</p>

<strong>Netlify</strong><br />

<p>I am currently hosting this website on netlify simply because, they rock. I love Graphql, Gatsby and the new JAMSTACK way of approaching web development. Netlify makes deploying websites like this super simple, with a super simple 'push to build' approach that makes changes and updates easier than ever. I have complete control over this site and I plan on using it frequently. So I wanted something that made my work easier.</p>

<strong>Digital Ocean</strong><br />

<p>Most of the sites and projects I work on or develop for clients on my own are hosted on a couple of droplets I own on Digital Ocean. They are well run with great support, simple to use, reliable and cheap. What else could you say?</p>

<strong>EMAIL</strong><br />

<p>Historically I always set up my own email servers on whatever website I was marketing. And since I have worked on many different projects, that became a lot of different domains. To make things easier, I had a basic gmail account that I would POP3 and SMTP all of the different domains to and use that. Since I am using Netlify for this site, I started using Fast mail to keep my own domain. But to check all of my different domains, now I just connect them all to my Thunderbird program.</p>

<strong>EDITOR AND TERMINAL</strong><br />

<p>My basic programming setup, when I am completely hooked up, consists of a basic bash terminal running the tmux multiplexer, vim (running on one or more windows of tmux), VS code to keep a quick eye on multiple files and for linting and firefox for my local server and any docs I may need.</p>

<strong>GEAR</strong><br />

16 in. 2019 Macbook Pro (work)<br> />
-OS: always up to date with the latest version<br />
-Processor: 2.6 Ghz 6 core i7<br />
-Memory: 16 GB<br />
-Storage: 750 GB<br />
Lenvovo Thinkpad (anonymity rules)<br />
-O.S.: Ubuntu 20.04<br />
-Processor: 2.7 GHz i5<br />
-Memory: 16 GB<br />
-Storage: 750 GB<br />
2 20 inch monitors<br />
Laptop Stand<br />
Bluetooth Earpiece for Sound and Mic<br />
iPhone 12 (for personal use and iOS development)<br />
Google Pixel Smart phone (for Android development)<br />
<p /><br />
<strong>APPS</strong><br />
Bash/zsh<br />
Chrome<br />
Firefox<br />
safari<br />
Thunderbird Mail<br />
VS code<br />
SpringTools Suite<br />
Sublime Text<br />
MongoDb Compass<br />
Vim<br />
W3m<br />
Gimp<br />
Inkscape<br />
Signal<br />
Tor Browser<br />
Docker<br />
Gpg<br />
iTerm<br />
Tmux<br />
Cash App<br />
Signal<br />
Chime<br />
Metasploit<br />
Postman<br />
Wireshark<br />
Mailchimp<br />
xCode<br />
Android Studio<br />
Libreoffice<br />
Git<br />`,
}
